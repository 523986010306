import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./GamingTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const GamingTable = ({ data, rowsPerPage, refreshFunction, pagination = true, disableHeader = false }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);


    return (
        <div className="GamingTable">
            {disableHeader != true &&
                <div className="row justify-content-start d-flex">
                    <div className="col-6 col-md-4">
                        <p className="fw-bold">Label</p>
                    </div>
                    <div className="col-4 d-none d-md-block">
                        <p className="fw-bold">IP</p>
                    </div>
                    <div className="col-5 col-md-2">
                        <p className="fw-bold">Status</p>
                    </div>
                </div>
            }

            {slice.map((gaming) => (
                <div onClick={() => window.location.assign(gaming.status === 'cancelled' ? '# ' : '/products/gaming/' + gaming.id)} key={gaming.id} className="row serverRow justify-content-start d-flex my-2 p-3">
                    <div className="col-6 col-md-4">
                        <p className="m-0 p-0">{gaming.label}</p>
                        <small className="m-0 p-0 d-none d-sm-flex">{gaming.subdomain + '.centnodes.io'}</small>
                    </div>
                    <div className="col-4 d-none d-md-block">
                        <p>{gaming.ip}</p>
                    </div>
                    <div className="col-5 col-md-2 d-mt-2">
                        {gaming.status === 'active' && <Badge bg="success">active</Badge>}
                        {gaming.status === 'cancelled' && <Badge bg="secondary">cancelled</Badge>}
                    </div>
                    <div className="col-1 d-none d-sm-block justify-content-end d-flex">
                        <p className="mt-1"><FontAwesomeIcon icon={faArrowRight} /></p>
                    </div>
                </div>
            ))}

            {pagination && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}

        </div>

    );
};

export default GamingTable;